import React, { Component, Fragment } from 'react';
import HeaderPortale from './HeaderPortale';
import FooterPortale from './FooterPortale';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ModaleErroreLogin from '../components/ModaleErroreLogin';
import ModaleLogin from '../components/ModaleLogin';
import ModaleModificaPassword from '../components/ModaleModificaPassword';
import { withRouter } from 'react-router-dom';
import { scaleDown as Menu } from 'react-burger-menu';
import MenuItem from '../containers/MenuItem';
import './burgermenu.css';

class HomePortale extends Component {

    state = {
        visualizzaModaleErroreLogin: false,
        visualizzaLogin: false,
        visualizzaModificaPassword: false,
        nomeUtente: null,
        usernameUtente: "",
        catStateOpen: "closed",
        loginEffettuato: false,
    };


    chiudiModaleClicked = () => {
        this.setState({
            visualizzaModaleErroreLogin: false,
            visualizzaLogin: false,
            visualizzaModificaPassword: false,
        });
    }

    accediClicked = () => {

        if (this.props.userProfile === null || this.props.userProfile.username === "") {
            this.setState({
                visualizzaModaleErroreLogin: false,
                visualizzaLogin: true
            });
        } else {
            this.props.effettuaLogout();
        }
    }

    loginClicked = (username, password) => {
        this.props.effettuaLogin(username, password);
    }

    getSnapshotBeforeUpdate = (prevProps, prevState) => {

        if (this.props.loginToken !== prevProps.loginToken &&
            this.props.messaggioErroreLogin === null &&
            this.props.loginToken === null) {
            return "logout";
        }

        // E' stato effettuato un login con successo (anche utente internet generico)
        if (this.props.loginToken !== prevProps.loginToken &&
            this.props.messaggioErroreLogin === null) {
            return "login_ok";
        }

        // E' stato effettuato un login con successo ma dobbiamo modificare la password
        if (this.props.codiceErroreLogin === 301 || this.props.codiceErroreLogin === 302) {

            if (this.state.visualizzaModificaPassword === false) {
                return "cambia_password";
            } else {
                return null;
            }
        }

        // E' stato effettuato un login con errore (anche utente internet generico)
        if (this.props.messaggioErroreLogin !== prevProps.messaggioErroreLogin &&
            this.props.messaggioErroreLogin !== null) {
            return "login_errore";
        }

        return null;
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if (snapshot === null) {
            return;
        }

        if (snapshot === "logout") {
            this.setState({
                visualizzaModaleErroreLogin: false,
                visualizzaLogin: false,
                nomeUtente: null,
                usernameUtente: "",
                visualizzaModificaPassword: false,
                loginEffettuato: false,
            });
        }

        if (snapshot === "login_ok") {
            this.setState({
                visualizzaModaleErroreLogin: false,
                visualizzaLogin: false,
                nomeUtente: this.props.userProfile.nome + " " + this.props.userProfile.cognome,
                usernameUtente: this.props.userProfile.username,
                visualizzaModificaPassword: false,
                loginEffettuato: true,
            });
        }

        if (snapshot === "cambia_password") {
            this.setState({
                visualizzaModaleErroreLogin: false,
                visualizzaLogin: false,
                nomeUtente: null,
                usernameUtente: "",
                visualizzaModificaPassword: true,
            });
        }

        if (snapshot === "login_errore") {
            this.setState({
                visualizzaModaleErroreLogin: true,
                visualizzaLogin: false,
                nomeUtente: null,
                usernameUtente: "",
                visualizzaModificaPassword: false,
            });
        }
    }

    componentDidMount = () => {
        if (this.props.loginToken === null) {
            this.props.effettuaLogin("", ""); // utente internet generico
        } else if (this.state.loginEffettuato === false) {
            this.setState({ loginEffettuato: true });
        }
    }

    toggleCatState = () => {
        this.setState({
            catStateOpen: this.state.catStateOpen === "open" ? "closed" : "open",
        });
    }

    render() {

        //const nomeUtente = (this.state.usernameUtente === "" ? "Accedi" : "Logout " + this.state.nomeUtente);
        const nomeUtente = ((this.props.userProfile && this.props.userProfile.username !== "") ? "Logout " + this.props.userProfile.nome + " " + this.props.userProfile.cognome : "Accedi");

        let centrale = null;

        if (this.state.visualizzaModificaPassword === true) {
            centrale = <Fragment>
                <span style={{ height: "300px", display: "block" }}>&nbsp;</span>
                <ModaleModificaPassword cambioPasswordConSuccesso={this.cambioPasswordConSuccesso} chiudiModaleClicked={this.chiudiModaleClicked} />
            </Fragment>;
        }

        if (this.state.visualizzaLogin === true) {
            centrale = <Fragment>
                <span style={{ height: "300px", display: "block" }}>&nbsp;</span>
                <ModaleLogin loginClicked={this.loginClicked} chiudiModaleClicked={this.chiudiModaleClicked} />
            </Fragment>;
        }

        if (this.state.visualizzaModaleErroreLogin === true) {
            centrale = <Fragment>
                <span style={{ height: "300px", display: "block" }}>&nbsp;</span>
                <ModaleErroreLogin messaggioErrore={this.props.messaggioErroreLogin} chiudiModaleClicked={this.chiudiModaleClicked} />
            </Fragment>;
        }

        const menuClicked = () => {
            Array.prototype.slice.call(document.getElementsByClassName("bm-burger-button")[0].children).filter((item) => { return item.localName === "button" })[0].click();
        }

        let menuEcm = null;

        if (this.state.loginEffettuato === true) {
            menuEcm = <React.Fragment> 
                <Menu burgerButtonClassName={"bm-burger-button"}
                burgerBarClassName={"bm-burger-bars"}
                crossButtonClassName={"bm-cross-button"}
                crossClassName={"bm-cross"}
                menuClassName={"bm-menu"}
                morphShapeClassName={"bm-morph-shape"}
                itemListClassName={"bm-item-list"}
                overlayClassName={"bm-overlay"}>
                <div className="side-menu-container">
                    <MenuItem idMenu={616} menuClickedHandler={menuClicked} />
                </div>
            </Menu>
                {/* <Menu burgerButtonClassName={"bm-burger-button"}
                    burgerBarClassName={"bm-burger-bars"}
                    crossButtonClassName={"bm-cross-button"}
                    crossClassName={"bm-cross"}
                    menuClassName={"bm-menu"}
                    morphShapeClassName={"bm-morph-shape"}
                    itemListClassName={"bm-item-list"}
                    overlayClassName={"bm-overlay"}>
                    <div className="side-menu-container">

                        <ul id="nav-main2" className="nav navbar-nav navmenu">

                            <li className="area" id="Area_18994">
                                <span className="spanArea" onClick={menuClicked}><NavLink to="/CreaQuestionariASSO">COMPILAZIONI</NavLink></span>
                            </li>

                            <li className="area" id="Area_18004">
                                <span className="spanArea" onClick={menuClicked}><NavLink to="/Stampe">STAMPE</NavLink></span>
                            </li>

                            <li className="area" id="Area_19003">
                                <span className="spanArea" onClick={menuClicked}><NavLink to="/RicercaUtentiPerASSO">Cerca ASSO</NavLink></span>
                            </li>

                            <li className="area" id="Area_19103">
                                <span className="spanArea" onClick={menuClicked}><NavLink to="/RicercaUtentiPerISA">Cerca ISA</NavLink></span>
                            </li>

                        </ul>

                    </div>
                </Menu> */}
            </React.Fragment>
        }

        return (
            <div id="outer-container">
                {menuEcm}
                <main id="page-wrap">
                    <div id="overlay" className="hidden"></div>
                    <div id="boxTotale" className="home">
                        <HeaderPortale nomeUtente={nomeUtente} accediClicked={this.accediClicked}></HeaderPortale>
                        <main className="main_container clearfix" id="corpo">{centrale === null ? this.props.children : centrale}</main>
                        <FooterPortale></FooterPortale>
                    </div>
                </main>
            </div>);
    }


}


// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        messaggioErroreLogin: state.messaggioErroreLogin,
        codiceErroreLogin: state.codiceErroreLogin,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogin: (username, password) => dispatch(actions.effettuaLogin(username, password)),
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};
// #endregion


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePortale));
