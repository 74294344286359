import React, { Component } from 'react';
import RicercaUtentiPerASSO from './containers/RicercaUtentiPerASSO';
import RicercaQuestionariASSO from './containers/RicercaQuestionariASSO';
import QuestionarioASSO from './containers/QuestionarioASSO';
import IndiceQuestionario from './containers/IndiceQuestionario';
import VisualizzaQuestionarioASSO from './containers/VisualizzaQuestionarioASSO';
import RisultatoValidazione from './containers/RisultatoValidazione';
import QuestionarioISA from './containers/QuestionarioISA';
import CreazioneQuestionari from './containers/CreazioneQuestionari';
import { Route, Switch, withRouter } from "react-router-dom";
import { Row, Grid, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import VisualizzaValidaQuestionarioISA from './containers/VisualizzaValidaQuestionarioISA';
import VisualizzaQuestionarioISA from './containers/VisualizzaQuestionarioISA';
import RicercaUtente from './containers/RicercaUtente';
import GestioneDatiUtente from './containers/GestioneDatiUtente';
import StampeQuestionari from './containers/StampeQuestionari';

import RicercaUtentiPerISA from './containers/RicercaUtentiPerISA';
import RicercaQuestionariISA from './containers/RicercaQuestionariISA';
import * as globals from './comuni/constants';

import Contenuto from './containers/Contenuto';

//VisualizzaQuestionariISA



class App extends Component {

  render() {

    if (this.props.userProfile !== null && this.props.userProfile.username !== "") {

      let sceglimenu = null;
      if (document.location.pathname === "/" || document.location.pathname === "/" + globals.urlSottoPortale + "/") {
        sceglimenu = (<Grid fluid={true}><Row><Col xs={12}><div style={{ display: "block", height: "300px", textAlign: "center" }}><h2>Usa il menu a sinistra per accedere alle funzionalità</h2></div></Col></Row></Grid>);
      }
      return (
        <div>
          {sceglimenu}

          <Switch>
            <Route path="/RicercaUtentiPerASSO" component={RicercaUtentiPerASSO} />
            {/* <Route path="/CreaQuestionariASSO" component={QuestionarioASSO_Switch} /> */}
            <Route path="/RicercaQuestionariASSO" component={RicercaQuestionariASSO} />
            <Route path="/QuestionarioASSO" component={QuestionarioASSO} />
            <Route path="/IndiceQuestionariASSO" component={IndiceQuestionario} />
            <Route path="/VisualizzaQuestionarioASSO" component={VisualizzaQuestionarioASSO} />
            <Route path="/RisultatoValidazione" component={RisultatoValidazione} />
            <Route path="/QuestionarioISA" component={QuestionarioISA} />
            <Route path="/CreazioneQuestionari" component={CreazioneQuestionari} />
            <Route path="/VisualizzaValidaQuestionarioISA" component={VisualizzaValidaQuestionarioISA} />
            <Route path="/CreaQuestionariASSO/CreaUtente" component={GestioneDatiUtente} />
            <Route path="/CreaQuestionariASSO" component={RicercaUtente} />
            <Route path="/Stampe" component={StampeQuestionari} />
            <Route path="/VisualizzaQuestionarioISA" component={VisualizzaQuestionarioISA} />

            <Route path="/RicercaUtentiPerISA" component={RicercaUtentiPerISA} />
            <Route path="/RicercaQuestionariISA" component={RicercaQuestionariISA} />

            <Route path="/ContenutoPagina" component={Contenuto} />
          </Switch>

        </div>);
    } else {

      return (
        <Switch>
          <Route path="/ContenutoPagina" component={Contenuto} />
          <Route path="/" children={<Disclaimer />} />
        </Switch>);

    }

  }
}

function Disclaimer() {
  return <Grid fluid={true}><Row><Col xs={12}><div style={{ display: "block", height: "auto", minHeight: "300px", textAlign: "center" }}><h2>Benvenuti sulla pagina dedicata ai modelli di valutazione del Servizio Orienta Lavoro del Comune di Pordenone.</h2>
    <p>
      Enti e operatori abilitati possono accedere all’ambiente di lavoro tramite credenziali, cliccando sul pulsante accedi in <strong>ALTO A DESTRA</strong>.<br></br>
      Per informazioni su ASSO - Attivazione Sociale Sostenibile Occupabilità e ISA -
  Indicatori Sviluppo Autonomie Integrazione Sociale Adultità, <br></br>utilizzare il menu di accesso alle pagine informative <br></br> Iniziativa sostenuta con il progetto Pon Inclusione - Codice Progetto AV3-2016-FVG-19</p>
    <p><img className="logo" src={process.env.PUBLIC_URL + "/img/logo/loghipartner.gif"} alt="Loghi Partner" /></p><br></br>AGGIUNGERE NUOVA FRASE: Pagine di informazione  sulle modalità di fruizione degli  strumenti di profilatura e valutazione ASSO e ISA</div></Col></Row></Grid>;

}

// #region Redux
const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

// #endregion

export default withRouter(connect(mapStateToProps)(App))

