import React, { Component } from 'react';
import axios from 'axios';
import * as globals from '../comuni/constants';
import { connect } from 'react-redux';
import { withRouter, /*NavLink*/ } from 'react-router-dom';
import queryString from 'query-string';
import ModaleErroreGenerica from '../components/ModaleErroreGenerica';
import Spinner from '../components/Spinner';
import 'moment/locale/it';
import moment from 'moment-timezone';
import { Row, Grid, Col } from 'react-bootstrap';


class Contenuto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idContenuto: null,
            contenuto: null,
            messaggioErrore: null,
            dataggiornamento: null,
            datapubblicazione: null,
        };

        moment.locale("it");
    }

    render() {
        if (this.state.messaggioErrore) {
            return <ModaleErroreGenerica informazioniErrore={this.state.messaggioErrore}
                chiudiModaleClicked={(e) => { this.setState(
                    { 
                        messaggioErrore: null, 
                        contenuto: { __html: "<div></div>" }, // Fatto per evitare un ri-caricamento. TODO? Inserire un msg di errore
                        idContenuto: null,
                        dataggiornamento: null,
                        datapubblicazione: null,
                    }); }} />
        }

        if (this.state.contenuto === null) {
            return <Spinner />;
        }

        const datapubblicazione = moment(this.state.datapubblicazione, "DD/MM/YYYY HH:mm:ss");
        const dataggiornamento = moment(this.state.dataggiornamento, "DD/MM/YYYY HH:mm:ss");

        return (
            <div>
                <div id="boxPaginaEcm" dangerouslySetInnerHTML={this.state.contenuto} />
                <div id="BoxRigaSuperiore" className="clearfix">
                    <Grid fluid={true}>
                        <Row>
                            <Col xs={12} sm={6} className="text-center">
                                Pubblicato il&nbsp;<span className="fa fa-calendar"></span>
                                <time dateTime={datapubblicazione.format("YYYY-MM-DDTHH:mm:ss")}>{datapubblicazione.format("dddd DD MMMM YYYY")}</time>
                            </Col>
                            <Col xs={12} sm={6} className="text-center">
                                Aggiornato il&nbsp;<span className="fa fa-calendar"></span>
                                <time dateTime={dataggiornamento.format("YYYY-MM-DDTHH:mm:ss")}>{dataggiornamento.format("dddd DD MMMM YYYY")}</time>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        if (this.state.contenuto === null) {
            this.fetchContent();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.contenuto !== this.state.contenuto) {
            window.scrollTo(0, 0);
        }

        if (!!this.state.idContenuto && this.state.idContenuto !== queryString.parse(this.props.location.search).ID) {
            this.fetchContent();
        }
    }


    fetchContent = () => {

        const data = {
            token: this.props.loginToken,
            method: "getcontenutopagina",
            param: {
                id: queryString.parse(this.props.location.search).ID,
            }
        };

        const successFn = (pagina) => {

            let contenuto = (pagina === null || pagina.contenuto === null) ? "" : pagina.contenuto;

            this.setState({
                contenuto: {
                    __html: sostituzioniContenuto(contenuto, pagina.cssfiles, this.props.loginToken),
                },
                messaggioErrore: null,
                idContenuto: queryString.parse(this.props.location.search).ID,
                dataggiornamento: pagina.dataggiornamento,
                datapubblicazione: pagina.datapubblicazione,
            });
        }

        const errorFn = (errorMessage) => {
            this.setState({
                messaggioErrore: errorMessage,
                contenuto: null,
                idContenuto: null,
                dataggiornamento: null,
                datapubblicazione: null,
            });
        }

        axios.post(globals.wsURLPDIBase, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const contenuto = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(contenuto);
                    successFn(contenuto);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    errorFn(response.data.result.message);
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("fetchContenuto: " + error);
                errorFn(error.message);
            });

        return;
    }


}

/// Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};


export const sostituzioniContenuto = (contenuto /* stringa con html */, 
                                      css /* array con nomi file a cui viene aggiunto http://www.indirizzo.com */, 
                                      token /* stringa con il loginToken */) => {

    const urlToChange = new RegExp('href="' + globals.urlPortale, "g");
    let changedUrl = 'href="';

    if (globals.urlSottoPortale.length > 0) {
        changedUrl += "/" + globals.urlSottoPortale;
    }

    // url assoluto -> sottocartella
    contenuto = contenuto.replace(urlToChange, changedUrl);

    // url assoluto -> sottocartella
    contenuto = contenuto.replace(urlToChange, changedUrl);

    // pagine di contenuto: link per portale asp.net -> link per portale react
    contenuto = contenuto.replace(new RegExp("/servizi/menu/dinamica.aspx", "g"), "/ContenutoPagina");

    // gestione documentale: link per portale asp.net -> link per portale react
    contenuto = contenuto.replace(new RegExp("/visualizzadocumento.aspx\\?", "g"), "/visualizzadocumento.aspx?token=" + token + "&");

    // tabelle qualsiasi: link per portale asp.net -> link per portale react
    contenuto = contenuto.replace(new RegExp("/download.aspx\\?", "g"), "/download.aspx?token=" + token + "&");

    // // file presenti in upload/pordenone_assoisa/notizie/
    // let idx = 0;
    // while (contenuto.indexOf("upload/pordenone_assoisa/notizie/", idx) > -1) {
    //     let quotesIdx = idx + "upload/pordenone_assoisa/notizie/".length;
    //     while (contenuto.charAt(quotesIdx) !== "'" && contenuto.charAt(quotesIdx) !== '"') {
    //         quotesIdx++;
    //     }
    //     const semi = contenuto.substring(0, quotesIdx) + "?token=" + token;
    //     contenuto = semi + contenuto.substring(quotesIdx);
    //     idx = semi.length;
    // }

    // Aggiungo in testa i css
    if (!!css) {
        const cssFiles = css.map((item, index) => {
            return '<link rel="stylesheet" href="' + globals.urlPortale + item + '" />';
        });

        contenuto = cssFiles.join("") + contenuto;
    }

    return contenuto;
}

// #endregion
export default withRouter(connect(mapStateToProps)(Contenuto));
